import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import clsx from "clsx";
import NextImage from "next/image";
type NewsletterSignUpImageProps = {
  readonly className?: string;
};
export function NewsletterSignUpImage({
  className
}: NewsletterSignUpImageProps) {
  return <div className={clsx("relative h-full sm:[clip-path:polygon(20%_0,100%_0,100%_100%,0%_100%)]", className)} data-sentry-component="NewsletterSignUpImage" data-sentry-source-file="NewsletterSignUpImage.component.tsx">
      <NextImage alt="" className="select-none object-cover mix-blend-multiply" draggable={false} fill={true} sizes={`(max-width: ${tailwindScreenSize.md}) 340px, 650px`} src="/images/newsletter/newsletter-sign-up-background.png" data-sentry-element="NextImage" data-sentry-source-file="NewsletterSignUpImage.component.tsx" />
    </div>;
}