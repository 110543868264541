import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { clsx } from "clsx";
type ArticleItemProps = {
  readonly article: ArticleTeaserModel;
  readonly trackingId?: null | string;
  readonly appearance: "ordered" | "unordered";
  readonly index: number;
  readonly orderedNumberClassname?: string;
  readonly position: string;
};
export function SimpleArticleListItem({
  appearance,
  article: {
    content: {
      targetUrl,
      id,
      headline: {
        kicker,
        plainText: headlineContent
      }
    }
  },
  index,
  orderedNumberClassname,
  position,
  trackingId
}: ArticleItemProps) {
  return <article className="relative flex gap-2" data-k5a-pos={position} key={targetUrl} data-sentry-component="SimpleArticleListItem" data-sentry-source-file="SimpleArticleListItem.component.tsx">
      {appearance === "ordered" ? <div className={clsx("shrink-0 basis-3 text-primary", orderedNumberClassname)}>
          {index + 1}
        </div> : null}

      <SnowplowLink className="block grow text-lg hover:underline lg:text-xl" href={targetUrl} trackingModel={{
      articleId: id,
      trackingId: trackingId ?? null,
      url: targetUrl
    }} data-sentry-element="SnowplowLink" data-sentry-source-file="SimpleArticleListItem.component.tsx">
        {kicker !== null ? <span>{kicker} </span> : null}
        {headlineContent}
      </SnowplowLink>
    </article>;
}