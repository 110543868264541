"use client";

import { AdSlot } from "@/components/AdSlot/AdSlot.component";
import { LoadingArticleTeaser } from "@/components/ArticleTeaser/LoadingArticleTeaser/LoadingArticleTeaser.component";
import { SwitchFetchable } from "@/components/SwitchFetchable/SwitchFetchable.component";
import type { DomId } from "@/hooks/ads/ads";
import type { ArticleTeaserModel } from "@/services/api/content/models/article-teaser-model.types";
import { type Fetchable, failed, isPending, pending, succeeded } from "@/services/utilities/fetchable";
import { useState } from "react";
import type { ReactNode } from "react";
type AdSlotWithSuggestedAndFallbackArticleProps = {
  readonly domId: DomId;
  readonly fallbackArticle?: ArticleTeaserModel | null;
  readonly trackingId?: null | string;
  readonly children?: ReactNode;
};
export function AdSlotWithArticleFallback({
  children = null,
  domId
}: AdSlotWithSuggestedAndFallbackArticleProps) {
  const [adStatus, setAdStatus] = useState<Fetchable>(pending());
  return <SwitchFetchable fetchable={adStatus} renderDefault={() => <>
          {isPending(adStatus) ? <LoadingArticleTeaser /> : null}

          <AdSlot className="mb-2 h-[160px] w-[320px] lg:mb-4 lg:h-[250px] lg:w-[300px]" domId={domId} onBeforeShow={isEmpty => setAdStatus(isEmpty ? failed("isEmpty") : succeeded())} />
        </>}
  /* @ts-expect-error -- if we render null, the default view will be displayed */ renderFailed={() => children ?? ""} data-sentry-element="SwitchFetchable" data-sentry-component="AdSlotWithArticleFallback" data-sentry-source-file="AdSlotWithArticleFallback.component.tsx" />;
}